<template>
 <div>
  <footer>
    <div class="container-fluid py-3" style=" background-color: #0b1328;">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mt-3">
            <div class="row">
              <div class="col-12 d-flex justify-content-center justify-content-lg-start">
                <img src="/assets/png/logobl.png" style="height: 2rem;" class="img-fluid" alt="">
              </div>
              <div class="col-12 mt-4">
                <p class="text-white" style="text-align: justify !important; ">
                  کانکتا بستر امنی برای کارفرما و فریلنسر ایجاد میکند تا فرایند تعریف، اجرا و پرداخت هزینه پروژه با سهولت و کیفیت انجام شده و هر دو طرف از مزایای آن بهره مند شوند. بازار کار آنلاین کانکتا این امکان را فراهم میکند تا فریلنسرهای برگزیده بهترین خدمات را به برترین کارفرماها ارائه دهند. کار کانکتا متصل کردن بهترینها به یکدیگر است
                </p>
              </div>
              <div class="col-12 mt-4 d-flex justify-content-center">
<router-link class="mx-3" to="">
  <i class="fab fa-telegram text-white"></i>
</router-link>
                <router-link class="mx-3" to="">
                  <i class="fab fa-instagram text-white"></i>
                </router-link>
                <router-link class="mx-3" to="">
                  <i class="fab fa-twitter text-white"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <div class="row">
              <div class="col-12">
                <h2 class="text-center text-white">
                  کانکتا
                </h2>
              </div>
              <div class="col-12 mt-4 d-flex justify-content-center">
               <router-link class="text-white text-center" to="">
                 تماس با ما
               </router-link>
              </div>
              <div class="col-12 mt-2 d-flex justify-content-center">
                <router-link class="text-white text-center" to="">
                  درباره ما
                </router-link>
              </div>
              <div class="col-12 mt-2 d-flex justify-content-center">
                <router-link class="text-white text-center" to>
                  وبلاگ
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <div class="row">
              <div class="col-12 col-lg-12">
               <h2 class="text-white text-center">نماد های اعتماد</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-12 d-flex justify-content-center">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
 </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>